import { createRouter, createWebHistory } from "vue-router";
import cognito from "../cognito/cognito";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/MainPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/catalog_upload",
    name: "CatalogUploadView",
    component: () =>
      import(
        /*webpackChunkName: "catalogUpload" */ "../views/CatalogUploadView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    cognito
      .isAuthenticated()
      .then(() => {
        next();
      })
      .catch(() => {
        next({ path: "/login" });
      });
  } else {
    return next();
  }
});

export default router;
