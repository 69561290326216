import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { Config, CognitoIdentityCredentials } from "aws-sdk";

class Cognito {
  configure(config) {
    if (config.userPool) {
      this.userPool = config.userPool;
    } else {
      this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId,
      });
    }
    Config.region = config.region;
    Config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: config.IdentityPoolId,
    });
    this.options = config;
    this.currentUser = false;
    return this;
  }

  install = (app, options) => {
    app.config.globalProperties.$cognito = this.configure(options);
    app.mixin({
      beforeCreate() {
        if (this.$options.cognito) {
          this._cognito = this.$options;
          this._cognito.configure(options);
        }
      },
    });
  };

  login(username, password, ...newPassword) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: function () {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.
          if (newPassword != "") {
            const _newPassword = newPassword[0];
            return cognitoUser.completeNewPasswordChallenge(
              _newPassword,
              {},
              {
                onSuccess: function (result) {
                  resolve(result);
                },
                onFailure: function (error) {
                  reject(error);
                },
              }
            );
          }
          reject("NewPasswordRequired");
        },
      });
    });
  }

  logout() {
    this.userPool.getCurrentUser().signOut();
  }

  isAuthenticated() {
    this.currentUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) {
        reject(this.currentUser);
      }
      this.currentUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            resolve(session);
          }
        }
      });
    });
  }
  forgetPassword(username) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  getAttributes() {
    return new Promise((resolve, reject) => {
      this.currentUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  resetPassword(username, newPassword, code) {
    const userData = { Username: username, Pool: this.userPool };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }
}

export default new Cognito();
