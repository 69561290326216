<template>
  <router-view />
</template>
<script>
export default {

};
</script>

<style lang="scss">
@import "bulma/bulma.sass";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

img {
  width: 60%;
}

nav {
  border: solid;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-width: 2px;
}
</style>
